@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
	html {
		color-scheme: dark;
	}
	body {
		color: white;
		background: black;
	}
}

/* -------- Custom Global Styles -------- */

.rainbow {
	background-image: radial-gradient(
		138.12% 1864.16% at 7.09% 4.38%,
		rgb(105, 65, 255) 0%,
		rgb(255, 95, 122) 30.87%,
		rgb(255, 161, 19) 100%
	);
	-webkit-box-decoration-break: clone;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	word-break: break-word;
}

.scrollShadows {
	max-height: 200px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;

	background:
    /* Shadow Cover TOP */ linear-gradient(
				white 30%,
				rgba(255, 255, 255, 0)
			)
			center top,
		/* Shadow Cover BOTTOM */
			linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
		/* Shadow TOP */
			radial-gradient(
				farthest-side at 50% 0,
				rgba(0, 0, 0, 0.2),
				rgba(0, 0, 0, 0)
			)
			center top,
		/* Shadow BOTTOM */
			radial-gradient(
				farthest-side at 50% 100%,
				rgba(0, 0, 0, 0.2),
				rgba(0, 0, 0, 0)
			)
			center bottom;

	background-repeat: no-repeat;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	background-attachment: local, local, scroll, scroll;
}

/* -------- Below here used for Stripe Navbar -------- */
/* :root {
	--white: #fff;
	--grey: #f1f4f8b0;
	--dark-grey: #6b7c93;
	--green: #24b47e;
	--teal: #4f96ce;
	--blue: #6772e5;
	--dark-blue: #4f3ef5;
	--spacer: 28px;
}

body {
	font-family: -apple-system, system-ui, 'Segoe UI', 'Roboto',
		'Helvetica Neue', Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	color: var(--dark-grey);
}

a {
	text-decoration: none;
	color: var(--blue);
}

a:hover,
a:focus {
	color: var(--dark-blue);
}

ul {
	list-style: none;
	padding-left: 0;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
} */

/* ---- Algolia Docs plugin ---- */
button.DocSearch.DocSearch-Button {
	color: #586274;
}
